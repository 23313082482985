import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Row, Col, ProductCard } from '../components/global/'

const Technicians = () => (
  <Layout>
    <SEO title="Technicians" />
    <div className="container">
      <h1>
        For <br />
        Technicians
      </h1>
      <p>
        You may be an experienced service technician with a lot of shop experience but little if any
        formal electrical training. You have learned to "get by" over the years doing electrical
        repairs.
      </p>
      <p>
        But, the one thing every technician needs, regardless of experience level, is Electrical
        Troubleshooting Training.
      </p>
      <p class="boost">
        Our training techniques emphasize a <strong>hands-on</strong> approach!
      </p>
      <p>
        Get up-to-speed quickly without spending too much time on electrical theory. Veejer training
        programs dramatically shorten the learning curve as technicians achieve a high level of
        electrical troubleshooting skill faster than they ever imagined they could.
      </p>
      <p>
        <a href="/Brochure_Retail_SP_2019.pdf" target="_blank" rel="noopener noreferrer">
          Click here for a PDF of our full training programs.
        </a>
      </p>
    </div>
    <div className="container container-last">
      <h3>Training A La Carte</h3>
      <p>Purchase only what you want. Take the training at your own pace.</p>
      <Row>
        <Col width="49%">
          <ProductCard
            title="Electrical SHORTCUTS"
            description='"Electrical SHORTCUTS" shows a technician how to troubleshoot vehicle electrical circuits on-the-vehicle with just a DMM and Current Clamp.'
            url="/products/shortcuts"
            type="secondary"
          />
        </Col>
        <Col width="49%">
          <ProductCard
            title="First Things First"
            description="FIRST THINGS FIRST is an auto/truck electrical  troubleshooting flip chart that focuses attention on problems in the primary electrical system."
            url="/products/first-things-first"
            type="secondary"
          />
        </Col>
      </Row>
      <Row>
        <Col width="49%">
          <ProductCard
            title="The Starter Kit H-111A"
            description="This is the first step in hands-on study of electrical circuit troubleshooting. The Starter Kit contains two fully assembled circuit boards that snap together and a DC power supply."
            url="/products/h-111a"
            type="secondary"
          />
        </Col>
        <Col width="49%">
          <ProductCard
            title="The 60 Lesson Course"
            description="This explains troubleshooting and repair of electrical-electronic problems encountered in cars, trucks and heavy equipment. These lessons show you how these circuits work, how they fail and how to troubleshoot them."
            url="/products/60-lessons"
            type="secondary"
          />
        </Col>
      </Row>
      <hr />
      <div className="testimonial">
        <p>
          "Bought the <Link to="/products/shortcuts">SHORTCUTS</Link> book several years ago and it
          (is) an immense help to my diagnostics abilities. The YouTube videos are excellent help as
          well. I recommend both to anyone in the automotive field."
          <span>Robert W. Elkin, NC</span>
        </p>
        <p>
          <a href="/">See other testimonials</a>
        </p>
      </div>
    </div>
  </Layout>
)

export default Technicians
